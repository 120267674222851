<template>
  <div class="row h-100 justify-content-between">
    <div class="d-none d-lg-block col-lg-2 col-xl-2 bg-white">
      <div class="pl-4">
        <Phaseline
            :phases="process.processPhases"
            :processId="process_id"
            :processLogo="getProcessLogo">
        </Phaseline>
      </div>
    </div>
    <div class="col-lg-9 col-xl-9 justify-content-start">
      <div class="d-flex flex-column px-6 mt-5">
        <!--::Process Info -->
        <div class="row">
          <div class="col-xl-12">
            <div class="card shadow-sm border-0 justify-content-center bgi-size-cover bgi-no-repeat ribbon ribbon-clip ribbon-right "
             :style="{'background-image': 'url(' + header_background + ')'}">
            <div class="ribbon-target p-3" style="top: 12px;">
              <span class="ribbon-inner" v-bind:class="{
                    'bg-primary': phase.phaseStatus && phase.phaseStatus.id == 2,
                    'bg-warning': phase.phaseStatus && phase.phaseStatus.id == 1,
                    'bg-danger': phase.phaseStatus && phase.phaseStatus.id == 3
                  }"></span>
              <span v-if="phase.phaseStatus" class=" text-uppercase font-weight-bolder">
                {{ phase.phaseStatus.localizations[0].name}}
              </span>
            </div>
              <!--begin::header-->
              <div
                class="card-header bg-transparent border-0 d-flex justify-content-between pt-5 pb-2"
              >
                <div v-if="phase.openDate && phase.closeDate" class="d-flex">
                  <div class="d-flex align-items-center">
<!--                    <span class="label label-primary label-inline p-4"><i class="fas fa-lock text-white"></i></span>-->

                    <span> {{$t('GENERALLABEL.DAL')  | capitalize}} <b class="font-weight-bolder h5">{{ phase.openDate | moment("DD/MM/Y")}}</b> </span>
                    <span class="ml-4"> {{$t('GENERALLABEL.AL')}} <b  class="font-weight-bolder h5">{{ phase.closeDate | moment("DD/MM/Y")}}</b></span>                  </div>
                </div>
              </div>
              <!--end::header-->
              <!--begin::Body-->
              <div class="card-body pt-2 pb-5">
                <div class="row w-100">
                  <div class="col-sm-12">
                    <h1 v-if="phase.localizations" class="text-primary font-weight-bolder mb-3">
                      {{phase.localizations[0].title}}
                    </h1>
                  </div>
                </div>

<!--                <div class="d-flex mb-1 align-items-center">-->
<!--                  <span class="d-block font-weight-bold mr-5">{{$t("PROCESSDETAIL.PROGRESS")}}</span>-->
<!--                  <div class="d-flex flex-row-fluid align-items-center">-->
<!--                    <div class="progress progress-xs mt-2 mb-2 w-100">-->
<!--                      <div-->
<!--                        class="progress-bar bg-warning"-->
<!--                        role="progressbar"-->
<!--                        aria-valuenow="50"-->
<!--                        aria-valuemin="0"-->
<!--                        aria-valuemax="100"-->
<!--                        :style="'width: ' + percentage + '%;'"-->
<!--                      ></div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

                <div class="row align-items-center">
                  <a
                      class="btn btn-default text-dark-75 font-weight-bolder rounded-0 mr-2 px-3 mr-2"
                      @click.prevent="alertInfoPhase"
                  >
                    <i class="fas fa-info-circle text-dark-75 "></i> {{$t("PROCESSPHASE.INFORMAZIONI")}}
                  </a>
<!--                   <span-->
<!--                       v-if="activeByCloseDate"-->
<!--                       class="">-->
<!--                     {{$t("GENERALLABEL.MANCANOANCORA")}}-->
<!--                     <b class="font-weight-bolder h5">10 giorni, 2 ore, 10 minuti</b>-->
<!--                     ( {{ phase.closeDate | moment("from", "now", true) }} )-->
<!--                     {{$t("GENERALLABEL.ALTERMINEDELLAFASE")}}-->
<!--                   </span>-->
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--::Tool mobile-->
        <div class="row my-2">
          <ProcessMenuMobile :process_id="process_id" :is_process_faq="isFaq" :is_process_guidelines="isGuidelines"></ProcessMenuMobile>
        </div>

        <div class="mt-5 row w-100 px-5">
          <div class="col-sm-12 d-flex justify-content-between">
            <h2 class="text-primary font-weight-bolder">
              {{$t('PROCESSPHASE.SCEGLIDOVEPARTECIPARE')}}:
            </h2>

            <div v-if="phase && phase.process2Modules" class="h2 text-primary font-weight-bolder">
              {{ phase.process2Modules.length }}
              <span class="h5 text-primary">{{ $t('PROCESSPHASE.CONTATOREMODULI') }}</span>
            </div>
          </div>
        </div>

        <div v-if="phase.process2Modules" class="">
          <!-- Ricordarsi di inserire nei bottoni dei moduli (partecipa, vota, visualizza) le condizioni per status delle fasi -->

          <VueSlickCarousel v-bind="settings" class="vue-slick-carousel">
            <div
              v-for="item in phase.process2Modules"
              :key="item.id"
              class="card-panel border-right border-light-dark border-top-0"
              style="max-width: 400px; min-width: 300px"
            >
              <PhaseModuleCard
                :phase="phase"
                :module="item"
                class="phase-module-card p-10"
              ></PhaseModuleCard>
            </div>
          </VueSlickCarousel>

        </div>

        <!--Versioning alert 1-->
        <div class="alert alert-custom alert-2x bg-secondary fade show my-5 py-2" role="alert" v-if="this.ideaInPs">
          <div class="alert-text text-uppercase font-weight-bolder ">
            <h5 class="aling-items-center mt-2 text-white"><i class="fas fa-layer-group text-white"></i> {{$t("PROCESSPHASE.ALERTBOZZA")}}
              <router-link class="text-white" :to="{
                      name: 'personalSpaceActivities',
                    }">
                <u>{{$t("PROCESSPHASE.PS")}}</u>
              </router-link>
            </h5>
          </div>
        </div>
        <!--end::Versioning alert-->

        <div class="row w-100 px-5 mt-5">
          <div class="col-sm-12">
            <h2 class="text-primary font-weight-bolder">
              {{$t('PROCESSPHASE.NAVIGATUTTELEPROPOSTE')}}:
            </h2>
          </div>
        </div>

        <!--::Search-->
        <div class="row w-100">
          <!-- Blocco search -->
          <div class="col-12 col-xl-12">
            <div class="row mx-6 w-100">
              <form method="get" class="quick-search-form w-100">
                <div class="input-group align-items-center border-bottom">
                  <div class="input-group-prepend bg-transparent  d-none d-sm-block">
                    <button
                        class="input-group-text line-height-0 py-0 bg-transparent border-0"
                        v-on:click="getSearchData($event)"
                    >
                      <img
                          src="/assets/media/bipart/ico-search.png"
                          alt=""
                          class="w-100"
                      />
                    </button>
                  </div>

                  <input
                      type="text"
                      class="form-control bg-transparent py-10 h4 border border-light-dark border-top-0 border-left-0 border-right-0"
                      placeholder="Search..."
                      v-model="keywords"
                  />
                  <div class="input-group-append d-none d-sm-block">
                    <span
                        class="input-group-text h5 text-primary font-weight-bold bg-transparent border-1 border-bottom-1 border-top-0 border-left-0 border-right-0"
                    >
                      <span v-if="phaseIdeaTotal" class="font-weight-bolder h2 mr-3">{{ phaseIdeaTotal }}</span> {{$t("PROCESSPHASE.IDEE")}}
                    </span>
                  </div>
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <a
                          href="#"
                          @click="setView('list', $event)"
                          id="pop-list"
                      >
                      <i class="fas fa-list text-primary"></i>
                      </a>
                    </span>
                    <span class="input-group-text">
                      <a
                          href="#"
                          @click="setView('grid', $event)"
                          id="pop-grid"
                      >
                        <i class="fas fa-th text-primary"></i>
                      </a>
                    </span>
                    <span class="input-group-text" v-if="displayViewMap">
                      <a
                          href="#"
                          @click="setView('map', $event)"
                          id="pop-map"
                      >
                        <i class="fas fa-map text-primary"></i>
                      </a>
                    </span>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>

        <!--::Desktop filters-->
        <div class="card card-custom my-3 d-none d-md-block">
          <b-nav>
            <b-nav-item :active="selectedOrder != null" :disabled="ideaView=='map' || (phaseIdeas && phaseIdeas.length == 0)">
              <b-dropdown id="dropdown-order" :text='$t("IDEALIST.ORDINA")' variant="transparent">

                <!--orderby-style -->
                <b-dropdown-item style="white-space: normal !important;" :class="['min-w-200px', selectedOrder == 1 ? 'selectedFilterStyle' : '']" v-on:click="orderBy($event, 'createdDate', 'desc'); handleSelectedOrder(1)">
                  <a href="#"
                  >{{$t("IDEALIST.ULTIMEIDEE")}}</a
                  >
                </b-dropdown-item>

                <!--orderby-style -->
                <b-dropdown-item style="white-space: normal !important;" :class="['min-w-200px', selectedOrder == 2 ? 'selectedFilterStyle' : '']" v-on:click="orderBy($event, 'createdDate', 'asc'); handleSelectedOrder(2)">
                  <a href="#"
                  >{{$t("IDEALIST.IDEEMENORECENTI")}}</a
                  >
                </b-dropdown-item>
                <!--                orderby-style -->
                <b-dropdown-divider v-if="isActiveFollow"></b-dropdown-divider>
                <b-dropdown-item
                    v-if="isActiveFollow"
                    style="white-space: normal !important;"
                    :class="['min-w-200px', selectedOrder == 3 ? 'selectedFilterStyle' : '']"
                    v-on:click="orderBy($event, 'countFollowers', 'desc'); handleSelectedOrder(3)"
                >
                  <a href="#">{{$t("IDEALIST.IDEEPIUSEGUITE")}}</a>
                </b-dropdown-item>
                <!--                orderby-style -->
                <b-dropdown-item
                    v-if="isActiveFollow"
                    style="white-space: normal !important;"
                    :class="['min-w-200px', selectedOrder == 4 ? 'selectedFilterStyle' : '']"
                    v-on:click="orderBy($event, 'countFollowers', 'asc'); handleSelectedOrder(4)"
                >
                  <a href="#"
                  >{{$t("IDEALIST.IDEEMENOSEGUITE")}}</a
                  >
                </b-dropdown-item>



                <b-dropdown-divider v-if="isActiveComment"></b-dropdown-divider>
                <b-dropdown-item
                    v-if="isActiveComment"
                    style="white-space: normal !important;"
                    :class="['min-w-200px', selectedOrder == 7 ? 'selectedFilterStyle' : '']"
                    v-on:click="orderBy($event, 'countIdeaComments', 'desc'); handleSelectedOrder(7)"
                >
                  <a href="#">{{$t("PROCESSPHASE.COMMENTIPIUNUMEROSI")}}</a>
                </b-dropdown-item>
                <!--                orderby-style -->
                <b-dropdown-item
                    v-if="isActiveComment"
                    style="white-space: normal !important;"
                    :class="['min-w-200px', selectedOrder == 8 ? 'selectedFilterStyle' : '']"
                    v-on:click="orderBy($event, 'countIdeaComments', 'asc'); handleSelectedOrder(8)"
                >
                  <a href="#"
                  >{{$t("PROCESSPHASE.COMMENTIMENONUMEROSI")}}</a
                  >
                </b-dropdown-item>




                <b-dropdown-divider v-if="hasBudget"></b-dropdown-divider>
<!--                <b-dropdown-item-->
<!--                    v-if="hasBudget"-->
<!--                    style="white-space: normal !important;"-->
<!--                    :class="['min-w-200px', selectedOrder == 6 ? 'selectedFilterStyle' : '']"-->
<!--                    v-on:click="orderBy($event, 'budgetMin', 'asc'); handleSelectedOrder(6)"-->
<!--                >-->
<!--                  <a href="#"-->
<!--                  >{{$t("IDEALIST.IDEEMENOCOSTOSE")}}</a-->
<!--                  >-->
<!--                </b-dropdown-item>-->
                <b-dropdown-item v-if="hasBudget" style="white-space: normal !important;" :class="['min-w-200px', selectedOrder == 5 ? 'selectedFilterStyle' : '']" v-on:click="orderBy($event, 'budgetMin', 'desc'); handleSelectedOrder(5)">
                  <a href="#"
                  >{{$t("IDEALIST.IDEECOSTOSE")}}</a
                  >
                </b-dropdown-item>

              </b-dropdown>
            </b-nav-item>

            <!-- Filtro modules -->
            <b-nav-item :disabled="!phase.process2Modules || (phase.process2Modules && phase.process2Modules.length <= 1)" :active="selectedModuleFilter !== 0 ? true : false" >
              <b-dropdown id="dropdown-order" :text='$t("IDEALIST.FILTRAPERMODULO")' variant="transparent" >
                <!--              <b-dropdown id="dropdown-order" text="FILTER BY MODULE" :variant="selectedModuleFilter !== 0 ? 'primary' : 'transparent'" >-->
                <b-dropdown-item style="white-space: normal !important;" class="min-w-200px" v-on:click="filterByModule($event, 0)">
                  <a href="#">{{$t("IDEALIST.NESSUNO")}}</a>
                </b-dropdown-item>
                <b-dropdown-item
                    v-for="item in phase.process2Modules"
                    :key="item.id"
                    style="white-space: normal !important;"
                    :class="['min-w-200px', item.id === selectedModuleFilter ? 'selectedFilterStyle' : '']" v-on:click="filterByModule($event, item.id)">
                  <a href="#">{{item.title}}</a>
                </b-dropdown-item>
              </b-dropdown>
            </b-nav-item>

            <!-- Filtro challenges -->
            <!--            <b-nav-item>-->
            <!--              <b-dropdown id="dropdown-order" text="FILTER BY CHALLENGE" variant="transparent">-->
            <!--                <b-dropdown-item class="min-w-200px" v-on:click="filterByPhaseChallenge($event, 0)">-->
            <!--                  <a href="#">{{$t("IDEALIST.NESSUNO")}}</a>-->
            <!--                </b-dropdown-item>-->
            <!--                <b-dropdown-item-->
            <!--                    v-for="item in processPhaseChallenges"-->
            <!--                    :key="item.id"-->
            <!--                    class="min-w-200px" v-on:click="filterByPhaseChallenge($event, item.id)">-->
            <!--                  <a href="#">{{item.name}}</a>-->
            <!--                </b-dropdown-item>-->
            <!--              </b-dropdown>-->
            <!--            </b-nav-item>-->

            <!-- Filtro challenges con esempi di stile condizionale per filtro selezionato -->
            <b-nav-item v-if="phase.countChallenges && phase.countChallenges > 0" :disabled="!processPhaseChallenges || (processPhaseChallenges && processPhaseChallenges.length == 0)" :active="selectedChallengeFilter !== 0 ? true : false">
              <b-dropdown id="dropdown-order" :text='isBandoZac ? "FILTRA PER FORMAT" : $t("IDEALIST.FILTRAPERCHALLENGE")' variant="transparent">
                <b-dropdown-item style="white-space: normal !important;" class="min-w-200px" v-on:click="filterByPhaseChallenge($event, 0)">
                  <a href="#">{{$t("IDEALIST.NESSUNO")}}</a>
                </b-dropdown-item>
                <b-dropdown-item
                    v-for="item in processPhaseChallenges"
                    :key="item.id"
                    style="white-space: normal !important;"
                    :class="['min-w-200px', item.id === selectedChallengeFilter ? 'selectedFilterStyle' : '']" v-on:click="filterByPhaseChallenge($event, item.id)">
                  <a href="#">{{item.name}}</a>
                </b-dropdown-item>
              </b-dropdown>
            </b-nav-item>

            <!-- Filtro scenari -->
            <b-nav-item v-if="process.countScenarios && process.countScenarios > 0" :disabled="!processPhaseScenarios || (processPhaseScenarios && processPhaseScenarios.length == 0)" :active="selectedScenarioFilter !== 0 ? true : false">
              <b-dropdown id="dropdown-order" :text='$t("IDEALIST.FILTRAPERSCENARIO")' variant="transparent" >
                <b-dropdown-item style="white-space: normal !important;" class="min-w-200px" v-on:click="filterByPhaseScenario($event, 0)">
                  <a href="#">{{$t("IDEALIST.NESSUNO")}}</a>
                </b-dropdown-item>
                <b-dropdown-item
                    v-for="item in processPhaseScenarios"
                    :key="item.id"
                    style="white-space: normal !important;"
                    :class="['min-w-200px', item.id === selectedScenarioFilter ? 'selectedFilterStyle' : '']" v-on:click="filterByPhaseScenario($event, item.id)">
                  <a href="#">{{item.title}}</a>
                </b-dropdown-item>
              </b-dropdown>
            </b-nav-item>

          </b-nav>
        </div>

        <!--::Mobile filters-->
        <div class="card card-custom my-3 d-block d-md-none">
          <b-nav>

            <b-nav-item :active="selectedOrder != null" :disabled="ideaView=='map' || (phaseIdeas && phaseIdeas.length == 0)" class="w-100">
              <b-dropdown id="dropdown-order" :text='$t("IDEALIST.ORDINA")' class="w-100" menu-class="w-100" variant="transparent">
                <!--                orderby-style -->
                <b-dropdown-item style="white-space: normal !important;" :class="['min-w-200px', selectedOrder == 1 ? 'selectedFilterStyle' : '']" v-on:click="orderBy($event, 'createdDate', 'desc'); handleSelectedOrder(1)">
                  <a href="#"
                  >{{$t("IDEALIST.ULTIMEIDEE")}}</a
                  >
                </b-dropdown-item>
                <!--                orderby-style -->
                <b-dropdown-item style="white-space: normal !important;" :class="['min-w-200px', selectedOrder == 2 ? 'selectedFilterStyle' : '']" v-on:click="orderBy($event, 'createdDate', 'asc'); handleSelectedOrder(2)">
                  <a href="#"
                  >{{$t("IDEALIST.IDEEMENORECENTI")}}</a
                  >
                </b-dropdown-item>
                <!--                orderby-style -->
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item style="white-space: normal !important;" :class="['min-w-200px', selectedOrder == 3 ? 'selectedFilterStyle' : '']" v-on:click="orderBy($event, 'countFollowers', 'desc'); handleSelectedOrder(3)">
                  <a href="#"
                  >{{$t("IDEALIST.IDEEPIUSEGUITE")}}</a
                  >
                </b-dropdown-item>
                <!--                orderby-style -->
                <b-dropdown-item style="white-space: normal !important;" :class="['min-w-200px', selectedOrder == 4 ? 'selectedFilterStyle' : '']" v-on:click="orderBy($event, 'countFollowers', 'asc'); handleSelectedOrder(4)">
                  <a href="#"
                  >{{$t("IDEALIST.IDEEMENOSEGUITE")}}</a
                  >
                </b-dropdown-item>
              </b-dropdown>
            </b-nav-item>

            <!-- Filtro modules -->
            <b-nav-item :disabled="!phase.process2Modules || (phase.process2Modules && phase.process2Modules.length <= 1)" :active="selectedModuleFilter !== 0 ? true : false" class="w-100">
              <b-dropdown id="dropdown-order" :text='$t("IDEALIST.FILTRAPERMODULO")' class="w-100" menu-class="w-100" variant="transparent" >
                <!--              <b-dropdown id="dropdown-order" text="FILTER BY MODULE" :variant="selectedModuleFilter !== 0 ? 'primary' : 'transparent'" >-->
                <b-dropdown-item style="white-space: normal !important;" class="min-w-200px" v-on:click="filterByModule($event, 0)">
                  <a href="#">{{$t("IDEALIST.NESSUNO")}}</a>
                </b-dropdown-item>
                <b-dropdown-item
                    v-for="item in phase.process2Modules"
                    :key="item.id"
                    style="white-space: normal !important;"
                    :class="['min-w-200px', item.id === selectedModuleFilter ? 'selectedFilterStyle' : '']" v-on:click="filterByModule($event, item.id)">
                  <a href="#">{{item.title}}</a>
                </b-dropdown-item>
              </b-dropdown>
            </b-nav-item>

            <!-- Filtro challenges -->
            <!--            <b-nav-item>-->
            <!--              <b-dropdown id="dropdown-order" text="FILTER BY CHALLENGE" variant="transparent">-->
            <!--                <b-dropdown-item class="min-w-200px" v-on:click="filterByPhaseChallenge($event, 0)">-->
            <!--                  <a href="#">{{$t("IDEALIST.NESSUNO")}}</a>-->
            <!--                </b-dropdown-item>-->
            <!--                <b-dropdown-item-->
            <!--                    v-for="item in processPhaseChallenges"-->
            <!--                    :key="item.id"-->
            <!--                    class="min-w-200px" v-on:click="filterByPhaseChallenge($event, item.id)">-->
            <!--                  <a href="#">{{item.name}}</a>-->
            <!--                </b-dropdown-item>-->
            <!--              </b-dropdown>-->
            <!--            </b-nav-item>-->

            <!-- Filtro challenges con esempi di stile condizionale per filtro selezionato -->
            <b-nav-item v-if="phase.countChallenges && phase.countChallenges > 0" :disabled="!processPhaseChallenges || (processPhaseChallenges && processPhaseChallenges.length == 0)" :active="selectedChallengeFilter !== 0 ? true : false" class="w-100">
              <b-dropdown id="dropdown-order" :text='isBandoZac ? "FILTRA PER FORMAT" : $t("IDEALIST.FILTRAPERCHALLENGE")' class="w-100" menu-class="w-100" variant="transparent">
                <b-dropdown-item style="white-space: normal !important;" class="min-w-200px" v-on:click="filterByPhaseChallenge($event, 0)">
                  <a href="#">{{$t("IDEALIST.NESSUNO")}}</a>
                </b-dropdown-item>
                <b-dropdown-item
                    v-for="item in processPhaseChallenges"
                    :key="item.id"
                    style="white-space: normal !important;"
                    :class="['min-w-200px', item.id === selectedChallengeFilter ? 'selectedFilterStyle' : '']" v-on:click="filterByPhaseChallenge($event, item.id)">
                  <a href="#">{{item.name}}</a>
                </b-dropdown-item>
              </b-dropdown>
            </b-nav-item>

            <!-- Filtro scenari -->
            <b-nav-item v-if="process.countScenarios && process.countScenarios > 0" :disabled="!processPhaseScenarios || (processPhaseScenarios && processPhaseScenarios.length == 0)" :active="selectedScenarioFilter !== 0 ? true : false" class="w-100">
              <b-dropdown id="dropdown-order" :text='$t("IDEALIST.FILTRAPERSCENARIO")' class="w-100" menu-class="w-100" variant="transparent" >
                <b-dropdown-item style="white-space: normal !important;" class="min-w-200px" v-on:click="filterByPhaseScenario($event, 0)">
                  <a href="#">{{$t("IDEALIST.NESSUNO")}}</a>
                </b-dropdown-item>
                <b-dropdown-item
                    v-for="item in processPhaseScenarios"
                    :key="item.id"
                    style="white-space: normal !important;"
                    :class="['min-w-200px', item.id === selectedScenarioFilter ? 'selectedFilterStyle' : '']" v-on:click="filterByPhaseScenario($event, item.id)">
                  <a href="#">{{item.title}}</a>
                </b-dropdown-item>
              </b-dropdown>
            </b-nav-item>

          </b-nav>
        </div>


        <!--::Waiting for API -->
        <div class="mt-5 mx-1 row">
          <!--::Module List/Grid/Map -->
          <div v-if="phaseIdeas && phaseIdeas.length > 0"  class="container-fluid px-0">

            <!--::Module List/Grid/Map -->
            <div v-if="ideaView == 'list'" class="row gutter-b">
              <div v-for="item in phaseIdeas" :key="item.id" class="col-xl-12 mb-4 align-items-center">
                <ListIdeaCard
                    :idea="item"
                    :process_id="process_id"
                    :process="process"
                    :phase_id="phase_id"
                    :phase_status_id="phase.phaseStatus.id"
                    :module_id="item.process2ModuleId"
                    :phase="phase"
                    @incFollower="incrementFollower($event)"
                    @decFollower="decrementFollower($event)"
                ></ListIdeaCard>
              </div>
            </div>
            <div v-if="ideaView == 'grid'" class="row gutter-b">
              <div v-for="item in phaseIdeas" :key="item.id" class="col-12 col-md-6 col-lg-4 col-xl-4">
                <GridIdeaCard
                    :idea="item"
                    :process_id="process_id"
                    :process="process"
                    :phase_id="phase_id"
                    :phase_status_id="phase.phaseStatus.id"
                    :module_id="item.process2ModuleId"
                    :phase="phase"
                    @incFollower="incrementFollower($event)"
                    @decFollower="decrementFollower($event)"
                ></GridIdeaCard>
              </div>
            </div>
            <div v-if="ideaView == 'map'">
              <MapIdeaBlock
                  :ideas="phaseIdeas"
                  :process_id="process_id"
                  :phase_id="phase_id"
                  :phase_status_id="phase.phaseStatus.id"
                  :phase="phase"
                  :process="process"
                  @incFollower="incrementFollower($event)"
                  @decFollower="decrementFollower($event)"
              ></MapIdeaBlock>
            </div>

            <infinite-loading @infinite="loadMore" spinner="bubbles" v-if="this.currentPage < this.pageCount && (ideaView=='list' || ideaView=='grid') && this.orderField"></infinite-loading>
          </div>
          <div v-else>
            <div class="row mb-5">
              <div class="col-xl-12 mb-4 align-items-center">
                {{ $t('PROCESSPHASE.NONCISONOPROPOSTE') }}
              </div>
            </div>
          </div>
        </div>
        <!--::End waiting for API -->

        <!--::To activate once done API for localized ideas calls -->

<!--        <b-tabs class="w-100">-->
<!--          <b-tab :active="ideaLang === 'it'">-->
<!--            <template v-slot:title>-->
<!--              <div-->
<!--                  class="p-3 border-bottom border-4"-->
<!--                  :class="[(ideaLang === 'it') ? 'border-bottom-primary' : 'border-bottom-light-dark']"-->
<!--                  @click="localizeData('it')"-->
<!--              >-->
<!--                IT-->
<!--              </div>-->
<!--            </template>-->
<!--            <b-card-text v-if="ideaLang === 'it'">-->
<!--              <div class="row w-100 mt-5">-->
<!--                &lt;!&ndash;::Module List/Grid/Map &ndash;&gt;-->
<!--                <div v-if="phaseIdeas.length > 0">-->

<!--                  &lt;!&ndash;::Module List/Grid/Map &ndash;&gt;-->
<!--                  <div v-if="ideaView == 'list'" class="row mb-5">-->
<!--                    <div v-for="item in phaseIdeas" :key="item.id" class="col-xl-12 mb-4 align-items-center">-->
<!--                      <ListIdeaCard-->
<!--                          :idea="item"-->
<!--                          :process_id="process_id"-->
<!--                          :phase_id="phase_id"-->
<!--                          :phase_status_id="phase.phaseStatus.id"-->
<!--                          :module_id="item.process2ModuleId"-->
<!--                          :phase="phase"-->
<!--                          @incFollower="incrementFollower($event)"-->
<!--                          @decFollower="decrementFollower($event)"-->
<!--                      ></ListIdeaCard>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div v-if="ideaView == 'grid'" class="row gutter-b">-->
<!--                    <div v-for="item in phaseIdeas" :key="item.id" class="col-12 col-md-6 col-lg-4 col-xl-4">-->
<!--                      <GridIdeaCard-->
<!--                        :idea="item"-->
<!--                        :process_id="process_id"-->
<!--                        :phase_id="phase_id"-->
<!--                        :phase_status_id="phase.phaseStatus.id"-->
<!--                        :module_id="item.process2ModuleId"-->
<!--                        :phase="phase"-->
<!--                        @incFollower="incrementFollower($event)"-->
<!--                        @decFollower="decrementFollower($event)"-->
<!--                      ></GridIdeaCard>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div v-if="ideaView == 'map'">-->
<!--                    <MapIdeaBlock-->
<!--                      :ideas="phaseIdeas"-->
<!--                      :process_id="process_id"-->
<!--                      :phase_id="phase_id"-->
<!--                      :phase_status_id="phase.phaseStatus.id"-->
<!--                      :phase="phase"-->
<!--                      :process="process"-->
<!--                      @incFollower="incrementFollower($event)"-->
<!--                      @decFollower="decrementFollower($event)"-->
<!--                    ></MapIdeaBlock>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div v-else>-->
<!--                  <div class="row mb-5">-->
<!--                    <div class="col-xl-12 mb-4 align-items-center">-->
<!--                      {{ $t('PROCESSPHASE.NONCISONOPROPOSTE') }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </b-card-text>-->
<!--          </b-tab>-->
<!--          <b-tab :active="ideaLang === 'en'">-->
<!--            <template v-slot:title>-->
<!--              <div-->
<!--                  class="p-3 border-bottom border-4"-->
<!--                  :class="[(ideaLang === 'en') ? 'border-bottom-primary' : 'border-bottom-light-dark']"-->
<!--                @click="localizeData('en')"-->
<!--              >-->
<!--                EN-->
<!--              </div>-->
<!--            </template>-->
<!--            <b-card-text v-if="ideaLang === 'en'">-->
<!--              <div class="row w-100 mt-5">-->
<!--                &lt;!&ndash;::Module List/Grid/Map &ndash;&gt;-->
<!--                <div v-if="phaseIdeas.length > 0">-->

<!--                  &lt;!&ndash;::Module List/Grid/Map &ndash;&gt;-->
<!--                  <div v-if="ideaView == 'list'" class="row mb-5">-->
<!--                    <div v-for="item in phaseIdeas" :key="item.id" class="col-xl-12 mb-4 align-items-center">-->
<!--                      <ListIdeaCard-->
<!--                          :idea="item"-->
<!--                          :process_id="process_id"-->
<!--                          :phase_id="phase_id"-->
<!--                          :phase_status_id="phase.phaseStatus.id"-->
<!--                          :module_id="item.process2ModuleId"-->
<!--                          :phase="phase"-->
<!--                          @incFollower="incrementFollower($event)"-->
<!--                          @decFollower="decrementFollower($event)"-->
<!--                      ></ListIdeaCard>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div v-if="ideaView == 'grid'" class="row gutter-b">-->
<!--                    <div v-for="item in phaseIdeas" :key="item.id" class="col-12 col-md-6 col-lg-4 col-xl-4">-->
<!--                      <GridIdeaCard-->
<!--                          :idea="item"-->
<!--                          :process_id="process_id"-->
<!--                          :phase_id="phase_id"-->
<!--                          :phase_status_id="phase.phaseStatus.id"-->
<!--                          :module_id="item.process2ModuleId"-->
<!--                          :phase="phase"-->
<!--                          @incFollower="incrementFollower($event)"-->
<!--                          @decFollower="decrementFollower($event)"-->
<!--                      ></GridIdeaCard>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div v-if="ideaView == 'map'">-->
<!--                    <MapIdeaBlock-->
<!--                        :ideas="phaseIdeas"-->
<!--                        :process_id="process_id"-->
<!--                        :phase_id="phase_id"-->
<!--                        :phase_status_id="phase.phaseStatus.id"-->
<!--                        :module_id="item.process2ModuleId"-->
<!--                        :phase="phase"-->
<!--                        :process="process"-->
<!--                        @incFollower="incrementFollower($event)"-->
<!--                        @decFollower="decrementFollower($event)"-->
<!--                    ></MapIdeaBlock>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div v-else>-->
<!--                  <div class="row mb-5">-->
<!--                    <div class="col-xl-12 mb-4 align-items-center">-->
<!--                      {{ $t('PROCESSPHASE.NONCISONOPROPOSTE') }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--              </div>-->
<!--            </b-card-text>-->
<!--          </b-tab>-->
<!--          <b-tab :active="ideaLang === 'el'">-->
<!--            <template v-slot:title>-->
<!--              <div-->
<!--                  class="p-3 border-bottom border-4"-->
<!--                  :class="[(ideaLang === 'el') ? 'border-bottom-primary' : 'border-bottom-light-dark']"-->
<!--                  @click="localizeData('el')"-->
<!--              >-->
<!--                EL-->
<!--              </div>-->
<!--            </template>-->
<!--            <b-card-text v-if="ideaLang === 'el'">-->
<!--              <div class="row w-100 mt-5">-->
<!--                &lt;!&ndash;::Module List/Grid/Map &ndash;&gt;-->
<!--                <div v-if="phaseIdeas.length > 0">-->

<!--                  &lt;!&ndash;::Module List/Grid/Map &ndash;&gt;-->
<!--                  <div v-if="ideaView == 'list'" class="row mb-5">-->
<!--                    <div v-for="item in phaseIdeas" :key="item.id" class="col-xl-12 mb-4 align-items-center">-->
<!--                      <ListIdeaCard-->
<!--                          :idea="item"-->
<!--                          :process_id="process_id"-->
<!--                          :phase_id="phase_id"-->
<!--                          :phase_status_id="phase.phaseStatus.id"-->
<!--                          :module_id="item.process2ModuleId"-->
<!--                          :phase="phase"-->
<!--                          @incFollower="incrementFollower($event)"-->
<!--                          @decFollower="decrementFollower($event)"-->
<!--                      ></ListIdeaCard>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div v-if="ideaView == 'grid'" class="row gutter-b">-->
<!--                    <div v-for="item in phaseIdeas" :key="item.id" class="col-12 col-md-6 col-lg-4 col-xl-4">-->
<!--                      <GridIdeaCard-->
<!--                          :idea="item"-->
<!--                          :process_id="process_id"-->
<!--                          :phase_id="phase_id"-->
<!--                          :phase_status_id="phase.phaseStatus.id"-->
<!--                          :module_id="item.process2ModuleId"-->
<!--                          :phase="phase"-->
<!--                          @incFollower="incrementFollower($event)"-->
<!--                          @decFollower="decrementFollower($event)"-->
<!--                      ></GridIdeaCard>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div v-if="ideaView == 'map'">-->
<!--                    <MapIdeaBlock-->
<!--                        :ideas="phaseIdeas"-->
<!--                        :process_id="process_id"-->
<!--                        :phase_id="phase_id"-->
<!--                        :phase_status_id="phase.phaseStatus.id"-->
<!--                        :module_id="item.process2ModuleId"-->
<!--                        :phase="phase"-->
<!--                        :process="process"-->
<!--                        @incFollower="incrementFollower($event)"-->
<!--                        @decFollower="decrementFollower($event)"-->
<!--                    ></MapIdeaBlock>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div v-else>-->
<!--                  <div class="row mb-5">-->
<!--                    <div class="col-xl-12 mb-4 align-items-center">-->
<!--                      {{ $t('PROCESSPHASE.NONCISONOPROPOSTE') }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </b-card-text>-->
<!--          </b-tab>-->
<!--        </b-tabs>-->

        <!--::End to activate once done API for localized ideas calls -->


      </div>
    </div>

    <div
      class="d-none d-lg-block col-lg-1 col-xl-1 justify-content-between  align-items-center"
    >
      <div class="d-flex flex-column mt-5 mr-5">
        <ProcessMenu :process_id="process_id"
                     :is_process_faq="isFaq"
                     :is_process_guidelines="isGuidelines"
                     :is_process_about="isAbout"
                     :is_process_narration = "isNarration"
        ></ProcessMenu>
      </div>
    </div>

    <RegisterOnPublic
        ref="registerOnPublic2"
        :process="process"
        @switchToLogin="manageLoginModal"
        @userRegisteredToProcess="manageUserRegisteredToProcess($event)"
    ></RegisterOnPublic>
    <LoginRegisterProcessOnPublic
        ref="loginRegisterProcessOnPublic"
        :process="process"
        @switchToRegister="manageRegisterModal"
        @userLoggedInAndRegisteredToProcess="manageUserLoggedInAndRegisteredToProcess()"
        @userLoggedIn = "manageUserLoggedIn"
    ></LoginRegisterProcessOnPublic>
    <LoggedRegisterProcessOnPublic
        ref="loggedRegisterProcessOnPublic"
        :process="process"
        @userLoggedRegisteredToProcess="manageUserLoggedRegisteredToProcess($event)"
    ></LoggedRegisterProcessOnPublic>

    <!-- Advanced Registration modals -->
    <adv-method-auth
        ref="methodAuth"
    ></adv-method-auth>
    <adv-role-auth
        ref="roleAuth"
        @switchToMobile="manageMobile"
    ></adv-role-auth>
    <adv-mobile-auth
        ref="mobileAuth"
        :process="process"
        @switchToMobileVerify="manageMobileVerify"
        @switchToPasswordRecovery="managePasswordRecovery"
    ></adv-mobile-auth>
    <adv-mobile-verify
        ref="mobileVerify"
        @switchToTaxcode="manageTaxcode"
    ></adv-mobile-verify>
    <adv-taxcode-auth
        ref="taxcodeAuth"
        :process="process"
        :module="currentIdeaModule"
        @switchToTaxcodeVerified="manageTaxcodeVerified"
    ></adv-taxcode-auth>
    <adv-auth-taxcode-verified
        ref="taxcodeVerified"
        :process="process"
    ></adv-auth-taxcode-verified>
    <adv-auth-taxcode-not-valid
        ref="taxcodeNotValid"
        :process="process"
    ></adv-auth-taxcode-not-valid>

    <adv-register-on-public
        ref="registerOnPublic"
        :process="process"
        @userRegistered="manageUserRegistered"
    ></adv-register-on-public>
    <adv-login-on-public
        ref="loginOnPublic"
        :process="process"
        @userLoggedIn="manageUserLogged"
    ></adv-login-on-public>
    <!-- END:: Advanced Registration modals -->

    <!-- Recovery password modals -->
    <password-recovery
        ref="passwordRecovery"
        @switchToOTPVerify="manageOTPVerify"
    >
    </password-recovery>
    <password-mobile-verify
        ref="passwordMobileVerify"
        @switchToPasswordChange="managePasswordChange"
    ></password-mobile-verify>
    <password-change
        ref="passwordChange"
    ></password-change>
    <!-- End Recovery password modals -->
  </div>
</template>


<script>
// slick
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "@/assets/css/themes/bipart/custom-slick-carousel.css";
// /slick
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import InfiniteLoading from 'vue-infinite-loading';

import Phaseline from "@/view/layout/common/Phaseline";
import ProcessMenu from "@/view/layout/common/ProcessMenu";
import ProcessMenuMobile from "@/view/layout/common/ProcessMenuMobile";

import { processMixin } from "@/mixins/process";
import { ideaMixin } from "@/mixins/idea";
import { alertMixin } from "@/mixins/alert";
import { matrixLight } from "@/mixins/matrixLight";
import Swal from "sweetalert2";

import ApiService from "@/core/services/api.service";
import ListIdeaCard from "@/view/pages/process/partials/ListIdeaCard";
import GridIdeaCard from "@/view/pages/process/partials/GridIdeaCard";
import PhaseModuleCard from "@/view/pages/process/partials/PhaseModuleCard";
import MapIdeaBlock from "@/view/pages/process/partials/MapIdeaBlock";

import RegisterOnPublic from "@/view/pages/auth/partials/RegisterOnPublic";
import LoginRegisterProcessOnPublic from "@/view/pages/auth/partials/LoginRegisterProcessOnPublic";
import LoggedRegisterProcessOnPublic from "@/view/pages/auth/partials/LoggedRegisterProcessOnPublic";

import { mapGetters } from "vuex";
import JwtService from "@/core/services/jwt.service";
import {RESEND_OTP_CODE} from "@/core/services/store/auth.module";
import AdvMethodAuth from "@/view/pages/auth/partials/advAuthMethod";
import AdvAuthTaxcodeVerified from "@/view/pages/auth/partials/advAuthTaxcodeVerified";
import AdvAuthTaxcodeNotValid from "@/view/pages/auth/partials/advAuthTaxcodeNotValid";
import AdvMobileVerify from "@/view/pages/auth/partials/advAuthMobileVerify";
import AdvTaxcodeAuth from "@/view/pages/auth/partials/advAuthTaxcode";
import AdvMobileAuth from "@/view/pages/auth/partials/advAuthMobile";
import AdvRoleAuth from "@/view/pages/auth/partials/advAuthRole";
import AdvRegisterOnPublic from "@/view/pages/auth/partials/advRegisterOnPublic";
import AdvLoginOnPublic from "@/view/pages/auth/partials/advLoginOnPublic";

import PasswordRecovery from "@/view/pages/auth/partials/PasswordRecovery";
import PasswordMobileVerify from "@/view/pages/auth/partials/PasswordMobileVerify";
import PasswordChange from "@/view/pages/auth/partials/PasswordChange";

export default {
  name: "process-phase",
  mixins: [processMixin, ideaMixin, alertMixin, matrixLight],
  components: {
    Phaseline,
    ProcessMenu,
    ProcessMenuMobile,
    ListIdeaCard,
    GridIdeaCard,
    MapIdeaBlock,
    PhaseModuleCard,
    VueSlickCarousel,
    RegisterOnPublic,
    LoginRegisterProcessOnPublic,
    LoggedRegisterProcessOnPublic,
    AdvMethodAuth,
    AdvAuthTaxcodeVerified,
    AdvAuthTaxcodeNotValid,
    AdvMobileVerify,
    AdvTaxcodeAuth,
    AdvMobileAuth,
    AdvRoleAuth,
    AdvRegisterOnPublic,
    AdvLoginOnPublic,
    InfiniteLoading,

    PasswordMobileVerify,
    PasswordRecovery,
    PasswordChange
  },
  static: {
    visibleItemsPerPageCount: 12
  },
  data() {
    return {
      followeSet: false,
      followSetValue: 0,
      followSetModuleValue: 0,
      hasProcessManyPhases: null,
      isFaq: null,
      isGuidelines: null,
      isAbout: null,
      isNarration: null,

      isActiveFollow: false,
      isActiveComment: false,
      hasBudget: false,

      displayViewMap: false,

      //nuovo
      processPhaseScenarios: [], //scenari del processo (filtro by scenario)
      filterScenarioId: 0,

      processPhaseChallenges: [], //challenges della fase (filtro by challenge)
      filterChallengeId: 0,

      // variabile per stile personalizzato su filtro selezionato
      selectedChallengeFilter: 0,
      selectedScenarioFilter: 0, // variabile per stile personalizzato su filtro selezionato
      selectedModuleFilter: 0, // variabile per stile personalizzato su filtro selezionato
      // /nuovo

      selectedOrder: null, //#orderby-style

      ideaInPs: false,
      // slick
      settings: {
        dots: true,
        arrows: false,
        dotsClass: "slick-dots custom-slick-dots",
        edgeFriction: 0.35,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,

        autoplay: true,
        autoplaySpeed: 2500,
        // focusOnSelect: true,
        // touchThreshold: 5,

        swipe: true,
        swipeToSlide: true,

        centerMode: false,
        initialSlide: 0,
        // centerPadding: "20px",

        variableWidth: false,

        pauseOnFocus: false,

        responsive: [
          {
            breakpoint: 2400,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 1900,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1450,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 730,
            settings: {
              slidesToShow: 1,
            },
            // settings: "unslick"
          },
        ],
      },
      // /slick
      process_id: this.$route.params.process_id,
      phase_id:this.$route.params.phase_id,
      process: [],
      phase: [],
      phaseIdeas: [],
      phaseIdeaTotal: 0,
      percentage: 0,
      phaseModulesId: [],
      strPhaseModulesId: '',
      orders: [
        {
          label: "data",
          field: "createdDate"
        },
        {
          label: "followers",
          field: "countFollowers"
        },
        {
          label: "budget",
          field: "budgetMin"
        }
      ],
      keywords: "",
      filterModuleId: 0,
      ideaView: 'grid',
      ideaLang: localStorage.getItem("language") || navigator.language.split("-")[0].toLowerCase(),

      orderField: "",
      orderVersus: "",

      currentIdeaModule: [],
      currentPage: 1,
      pageCount: 0,

    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "getOrganization"
    ]),

    isBandoZac() {
      return window.location.host.includes('bandozac.bipart.it');
    },

    activeByCloseDate() {
      let isActive = true;
      if(this.phase.automaticallyClosed) {
        isActive = this.phase.open;
      } else {
        let d0 = moment(new Date()).format("YYYYMMDD");
        let d1 = moment(this.phase.closeDate).format("YYYYMMDD");
        if(d0 >= d1)
          isActive = false;
      }
      return isActive;
    },
    is_anonymous(){
      return (this.currentUser.authorities && this.currentUser.authorities.includes('SCOUT'));
    },
    is_anonymous_on_public() {
      if(this.getOrganization.data) {
        return (this.getOrganization.data.publiclyVisible && this.currentUser.id == 5);
      } else {
        return this.currentUser.id === 5;
      }
    },
    header_background() {
      let bg_img = '/assets/media/ripartire/header-process.jpg';
      if(window.location.host.includes('darsenaravenna.bipart.it') || window.location.host.includes('stage.comunediravenna.bipart.it'))
        bg_img = '/assets/media/ripartire/header-process-dare.png';
      if(window.location.host.includes('spendiamolinsieme.bipart.it') || window.location.host.includes('stage.spendiamolinsieme.bipart.it'))
        bg_img = '/assets/media/spendiamolinsieme/header-process-si.png';

      if(window.location.host.includes("bandozac.bipart.it")) {
        bg_img = "/assets/media/bandozac/header.png";
      }

      return bg_img;

      // return (window.location.host.includes('darsenaravenna.bipart.it') || window.location.host.includes('stage.comunediravenna.bipart.it')) ? '/assets/media/ripartire/header-process-dare.png' : '/assets/media/ripartire/header-process.jpg';
    },

    is_email_pending() {      
      return  (this.currentUser.selectedOrganizationPublic && this.currentUser.emailRegStatus == 'PENDING')
    },
    is_email_confirmed() {         
      return  (this.currentUser.selectedOrganizationPublic && this.currentUser.emailRegStatus == 'COMPLETE')
    },

    is_mobile_pending() {         
      return  (this.currentUser.selectedOrganizationPublic && this.currentUser.mobileRegStatus == 'PENDING')
    },
    is_mobile_confirmed() {         
      return  (this.currentUser.selectedOrganizationPublic && this.currentUser.mobileRegStatus == 'COMPLETE')
    },
  },
  watch: {
    '$route.params.phase_id'() {
      this.$isLoading(true);

      this.settings.responsive = [];

      this.phase_id = this.$route.params.phase_id;
      this.strPhaseModulesId = '';
      this.currentPage = 1;
      this.pageCount = 0;

      this.getPhaseData();

      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("BCPROCESSI.ELENCOPROCESSI"), route: "/process-list" },
        { title: this.process.title, route: this.hasProcessManyPhases ?  "/process-detail/" + this.process_id : "disabled" },
        // { title: this.process.localizations[0].title, route: "/process-detail/" + this.process_id },
        { title: this.phase.title },
      ]);

      this.settings.responsive = [
        {
          breakpoint: 2400,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1450,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 730,
          settings: {
            slidesToShow: 1,
          },
          // settings: "unslick"
        },
      ],

      this.$isLoading(false);
    }
  },
  mounted() {
    // this.$store.dispatch(SET_BREADCRUMB, [
    //   { title: this.$t("BCPROCESSI.ELENCOPROCESSI"), route: "/process-list" },
    //   // { title: this.$t("BCPROCESSI.PROCESSO"), route: "/process-detail/" + this.process_id },
    //   // { title: this.process.localizations[0].title, route: "/process-detail/" + this.process_id },
    //   { title: this.$t("PROCESSPHASE.BREADCRUMBTITLE")},
    // ]);
  },
  async created() {
    this.$isLoading(true);
    // const lang = localStorage.getItem("language") || navigator.language.split("-")[0].toLowerCase();
        

    this.process = await this.getProcess(this.process_id, true);    
    this.isFaq = this.isTrue(this.process.faq);
    this.isGuidelines = this.isTrue(this.process.guidelines);
    this.isAbout = this.isTrue(this.process.about);
    this.isNarration = this.isTrue(this.process.narration);    

    this.percentage = this.getPercentage(this.process);    

    await this.getPhaseData();
    //#n
    this.processPhaseScenarios = this.process.scenarios; //scenari del processo (filtro by scenario)    
    // /

    this.hasProcessManyPhases = this.process.countProcessPhases > 1;

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("BCPROCESSI.ELENCOPROCESSI"), route: "/process-list" },
      { title: this.process.title, route: this.hasProcessManyPhases ?  "/process-detail/" + this.process_id : "disabled" },
      // { title: this.process.localizations[0].title, route: "/process-detail/" + this.process_id },
      { title: this.phase.title },
    ]);

    //this.ideasInPs();

    /*
    this.phase = this.process.processPhases.find( item => {
      return (item.id == this.phase_id);
    });    
    // this.phaseModulesId = this.process.processPhases.map( (item) => {
    //   return item.id;
    // });

    // this.process.processPhases.forEach( (item) => {
    //   this.strPhaseModulesId += item.id + ',';
    // })

    this.phase.process2Modules.forEach( (item) => {
      this.strPhaseModulesId += item.id + ',';
    })

    this.phaseIdeas = this.getPhaseIdeas();
            
    */

    this.$isLoading(false);
  },
  methods: {
    isTrue(prop) {
      return prop && prop != "";
    },

    can_access_module(module, action = "") {      
      return this.$canAccessModule(module, action);
    },

    // isActiveFollowing() {
    //   return this.process.process2Modules.some(module => module.activeFollowing == true);
    // },

    handleSelectedOrder(value) { // #orderby-style
      this.selectedOrder = value;
    },
    async manageUserLoggedRegisteredToProcess() {
      this.alertUserLoggedRegisteredToProcess( this.process.title );

      if (this.followeSet && this.followSetValue && this.followSetValue > 0) {

        let qs = "?ideaId.equals=" + this.followSetValue;

        let endpoint = this.generateUrl('idea-followers', false, false) + qs;
        //generateUrl is done
        ApiService.get(this.$apiResourceOpen, endpoint)
            .then(res => {
              if (res.data.some(x => x?.follower?.id == this.currentUser.id)) {

                let incFollowerObj = {
                  idea: this.followSetValue,
                  module: this.followSetModuleValue
                }
                this.decrementFollower(incFollowerObj);

              } else {

                let decFollowerObj = {
                  idea: this.followSetValue,
                  module: this.followSetModuleValue
                }
                this.incrementFollower(decFollowerObj);
              }
            });
      }
    },
    manageUserLoggedInAndRegisteredToProcess() {
      this.alertUserLoggedInAndRegisteredToProcess( this.process.title );
    },
    async manageUserLoggedIn() {

      this.alertUserLoggedIn(this.process.title);

      if (this.followeSet && this.followSetValue && this.followSetValue > 0) {

        let qs = "?ideaId.equals=" + this.followSetValue;

        let endpoint = this.generateUrl('idea-followers', false, false) + qs;
        //generateUrl is done
        ApiService.get(this.$apiResourceOpen, endpoint)
        .then(res => {
          if (res.data.some(x => x?.follower?.id == this.currentUser.id)) {

            let incFollowerObj = {
              idea: this.followSetValue,
              module: this.followSetModuleValue
            }
            this.decrementFollower(incFollowerObj);

          } else {

            let decFollowerObj = {
              idea: this.followSetValue,
              module: this.followSetModuleValue
            }
            this.incrementFollower(decFollowerObj);
          }
        });
      }
    },
    manageRegisterModal() {
      this.$refs["registerOnPublic2"].$refs["modalRegisterOnPublic"].show();
    },
    manageLoginModal() {
      this.$refs["loginRegisterProcessOnPublic"].$refs["modalLoginRegisterProcessOnPublic"].show();
    },
    manageUserRegisteredToProcess() {      
      this.alertUserRegisteredToProcess(this.process.title);
    },
    userHasProcess(value) {
      return this.currentUser.assignedProcesses.includes(value)
    },

    async incrementFollower(obj) {
      let ideaId = obj.idea;
      let moduleId = obj.module.id;
      let endpointCanFollow = await this.$endpointCanFollow(moduleId);
      this.currentIdeaModule = await this.getCurrentModule(this.process, moduleId);

      if(endpointCanFollow) {
        if (this.is_anonymous_on_public) {
          if (this.currentIdeaModule.advancedRegistrationRequiredForFollowing) {
            if (this.checkUserStatus()) {
              this.followerAdd(ideaId, this.currentUser.id);
              this.alertAddFollower();
              this.followeSet = false;
              this.followSetValue = null;
            } else {
              this.followeSet = true;
              this.followSetValue = ideaId;
              this.followSetModuleValue = moduleId;
            }
          } else {
            this.$refs["loginRegisterProcessOnPublic"].$refs["modalLoginRegisterProcessOnPublic"].show();
            this.followeSet = true;
            this.followSetValue = ideaId;
            this.followSetModuleValue = moduleId;
          }
        } else if(!this.is_anonymous_on_public && this.is_mobile_pending && this.is_email_pending) {
          this.alertEmailMobileConfirm();
        } else if(!this.is_anonymous_on_public && this.is_mobile_pending && !this.is_email_confirmed) {
          this.alertMobileConfirm();
        }  else if (!this.is_anonymous_on_public && this.is_email_pending && !this.is_mobile_confirmed) {
          this.alertEmailConfirm();
        } else if (!this.is_anonymous_on_public && !this.userHasProcess(this.process.id)) {
          this.$refs["loggedRegisterProcessOnPublic"].$refs["modalLoggedRegisterProcessOnPublic"].show();
          this.followeSet = true;
          this.followSetValue = ideaId;
          this.followSetModuleValue = moduleId;
        } else {
          if (this.currentIdeaModule.advancedRegistrationRequiredForFollowing) {
            if (this.checkUserStatus()) {
              if (this.can_access_module(obj.module, "follow")) {
                this.followerAdd(ideaId, this.currentUser.id);
                this.alertAddFollower();
                this.followeSet = false;
                this.followSetValue = null;
                this.followSetModuleValue = null;
              } else {
                this.alertUserModuleAccessBitmaskFollow();
              }
            } else {
              this.followeSet = true;
              this.followSetValue = ideaId;
              this.followSetModuleValue = moduleId;
            }
          } else {
            if (this.can_access_module(obj.module, "follow")) {
              this.followerAdd(ideaId, this.currentUser.id);
              this.alertAddFollower();
              this.followeSet = false;
              this.followSetValue = null;
              this.followSetModuleValue = null;
            } else {
              this.alertUserModuleAccessBitmaskFollow();
            }

          }
        }

      } else {

        this.alertCanNotFollow();
      }
    },

    async decrementFollower(obj) {

      let ideaId = obj.idea;
      let moduleId = obj.module.id;      
      let endpointCanFollow = await this.$endpointCanFollow(moduleId);

      if (endpointCanFollow) {

        if (this.is_anonymous_on_public) {
          this.$refs["loginRegisterProcessOnPublic"].$refs["modalLoginRegisterProcessOnPublic"].show();
          this.followeSet = true;
          this.followSetValue = ideaId;
          this.followSetModuleValue = moduleId;
        } else if(!this.is_anonymous_on_public && this.is_mobile_pending && this.is_email_pending) {
          this.alertEmailMobileConfirm();
        } else if(!this.is_anonymous_on_public && this.is_mobile_pending && !this.is_email_confirmed) {
          this.alertMobileConfirm();
        }  else if (!this.is_anonymous_on_public && this.is_email_pending && !this.is_mobile_confirmed) {
          this.alertEmailConfirm();
        } else if (!this.is_anonymous_on_public && !this.userHasProcess(this.process.id)) {
          this.$refs["loggedRegisterProcessOnPublic"].$refs["modalLoggedRegisterProcessOnPublic"].show();
          this.followeSet = true;
          this.followSetValue = ideaId;
          this.followSetModuleValue = moduleId;
        } else {
          if (this.currentIdeaModule.advancedRegistrationRequiredForFollowing) {
            if (this.checkUserStatus()) {
              if (this.can_access_module(obj.module, "follow")) {
                this.followerDel(ideaId, this.currentUser.id);
                this.alertRemoveFollower();
                this.followeSet = false;
                this.followSetValue = null;
                this.followSetModuleValue = null;
              } else {
                this.alertUserModuleAccessBitmaskFollow();
              }
            } else {
              this.followeSet = true;
              this.followSetValue = ideaId;
              this.followSetModuleValue = moduleId;
            }
          } else {
            if (this.can_access_module(obj.module, "follow")) {
              this.followerDel(ideaId, this.currentUser.id);
              this.alertRemoveFollower();
              this.followeSet = false;
              this.followSetValue = null;
              this.followSetModuleValue = null;
            } else {
              this.alertUserModuleAccessBitmaskFollow();
            }
          }
        }

      } else {
        this.alertCanNotFollow();
      }
    },

    // Method for advanced registration
    checkUserStatus() {
      let token = JwtService.decodeToken()      
      if(this.is_anonymous) {                         // Utente anonimo
        this.$refs["methodAuth"].$refs["modalMethod"].show();
      }
      else if(token.mobileRegStatus == 'BLANK') {     // Utente registrato ma senza cellulare
        this.$refs["roleAuth"].$refs["modalRole"].show();
      }
      else if( token.mobileRegStatus == 'PENDING') {  // Utente registrato ma con cellulare non confermato
        this.$store.dispatch(RESEND_OTP_CODE, this.currentUser.id);
        this.$refs["mobileVerify"].$refs["modalSms"].show();
      }
      else if(!this.currentIdeaModule.taxcodeValidationRequiredForAdvancedRegistration) {
        return true;
      }
      else if( token.mobileRegStatus == 'COMPLETE' && !token.taxcodePresent ) { // Utente registrato con cellulare confermato ma senza cf
        this.$refs["taxcodeAuth"].$refs["modalCf"].show();
      }
      else if( token.mobileRegStatus == 'COMPLETE' && token.taxcodePresent ) {
        // Utente registrato con cellulare confermato e cf validato
        // Analizzo se il codice fiscale è valido per questo modulo
        
        let user_enabled = 0;
        if(token.taxcodeP2MStatus.length > 0) {
          token.taxcodeP2MStatus.forEach( (value) => {
            // if( value.p2mId == this.module.id && value.status == 'PASSED' ) {
            //   user_enabled = true;
            // }
            if( value.p2mId == this.currentIdeaModule.id ) {
              if(value.status == 'PASSED') {
                user_enabled = 1;
              } else {
                user_enabled = -1;
              }
            }
          })
        }
        
        if(user_enabled == 1) {
          return true;
        }
        else if(user_enabled == -1) {
          // Utente non abilitato al voto
          this.$refs["taxcodeNotValid"].$refs["modalTaxcodeNotValid"].show();
        }
        else {
          // Utente non ancora validato sul modulo
          this.$refs["taxcodeAuth"].$refs["modalCf"].show();
        }
      }      
      return false;
    },

    manageMobile() {
      this.$refs["mobileAuth"].$refs["modalPhone"].show();
    },
    manageMobileVerify(role) {
      this.user_role = role;
      this.$refs["mobileVerify"].$refs["modalSms"].show();
    },
    manageTaxcode() {
      if(this.currentIdeaModule.taxcodeValidationRequiredForAdvancedRegistration) {
        this.$refs["taxcodeAuth"].$refs["modalCf"].show();
      } else if(this.checkUserStatus()) {
        // Messaggio di registrazione effettuata
        this.$refs["taxcodeVerified"].$refs["modalTaxcodeVerified"].show();
        this.manageVoteByFlag();
      }
    },

    manageTaxcodeVerified() {
      this.$refs["taxcodeVerified"].$refs["modalTaxcodeVerified"].show();
      this.manageVoteByFlag();
      
    },
    manageUserRegistered() {
      this.$refs["roleAuth"].$refs["modalRole"].show();
    },
    manageUserLogged() {
      this.updateLocalIdeaData();
      // this.getCurrentIdea(this.id)
      //     .then( res => {
      //       this.idea = res.data;
      //       this.voto4 = res.data.type4VotedByAuthUser > 0 ? res.data.type4VotedByAuthUser : "";      
      //     });

      if(this.checkUserStatus()) {
        this.manageVoteByFlag();
      }
    },
    // End method for advanced registration

    async getPhaseData() {
      this.phase = this.process.processPhases.find( item => {
        return (item.id == this.phase_id);
      });      

      // Raccolta degli id dei moduli della fase per selezione idee della fase
      // e attivazione dell'ordinamento per follow e per numero commenti se i
      // rispettivi flag in almento un modulo della fase sono attivi
      this.isActiveFollow = false;
      this.isActiveComment = false;
      this.phase.process2Modules.forEach( (item) => {
        this.strPhaseModulesId += item.id + ',';
        if(item.activeFollowing == 1) {
          this.isActiveFollow = true;
        }
        if(item.activeCommenting == 1) {
          // this.isActiveComment = true;
        }
      })

      // Analisi dei moduli di voto per attivazione/disattivazione ordinamenti
      // this.isActiveFollow = this.phase.processVotes.some( item => {
      //   return item.valueVotes1ForIdeas > 0
      // })

      // this.hasBudget = this.phase.processVotes.some( item => {
      //   return item.valueVotes4ForIdeas > 0
      // })

      //#n
      this.processPhaseChallenges = this.phase.challenges;
      // /

      this.getPhaseIdeas().then(() => {
        if(this.phaseIdeas.length > 2)
          this.ideaView = "grid"
      });

    },
    alertInfoPhase(){
      Swal.fire({
        title: (this.phase.localizations) ? this.phase.localizations[0].title : this.$t("IDEALIST.INFORMAZIONISULLAFASE"),
        width: 700,
        html: (this.phase.localizations) ? this.phase.localizations[0].description : this.$t("IDEALIST.INFORMAZIONISULLAFASE"),
        icon: "info",
        confirmButtonClass: "btn btn-primary",
        confirmButtonText: 'Ho capito!',
      });
    },

    loadMore($state) {      
      setTimeout(() => {
                

        if(this.currentPage < this.pageCount) {

          this.currentPage += 1

          return new Promise((resolve, reject) => {
            // let qs = "process2ModuleId.equals=" + this.id;
            let qs = (this.filterModuleId > 0) ?
                "&process2ModuleId.equals=" + this.filterModuleId :
                "&process2ModuleId.in=" +  this.strPhaseModulesId.slice(0, -1);

            qs += "&size=" + this.$options.static.visibleItemsPerPageCount + "&page=" + this.currentPage;

            if (this.filterScenarioId > 0)
              qs += "&scenarioId.equals=" + this.filterScenarioId;

            if (this.filterChallengeId > 0)
              qs += "&challengeId.equals=" + this.filterChallengeId;

            if (this.keywords) qs += "&title.contains=" + this.keywords;

            if (this.orderField)
              qs += "&sort=" + this.orderField + "," + this.orderVersus;

            //Visualizza solo le idee pubbliche
            qs += "&ideaStatusId.equals=3";

            //Visualizza solo la verisiona pubblicata
            qs += "&versionCurrent.equals=true"

            let endpoint = this.generateUrl('ideas-page', true) + "?locs_=descr&fks_=logo,istatus,auth,isast&cnt_=i2foll,icomm&val_=lvv4"  + qs;
            //generateUrl is done            
            ApiService.get(this.$apiResourceOpen, endpoint)
              .then(res => {                            
                if((res.data.content) && res.data.content.length > 0) {
                  res.data.content.forEach(element => {
                    this.phaseIdeas.push(element);
                  })                  
                }

                $state.loaded();
              })
              .catch(() => {                
                this.$isLoading(false);
                reject(0);
              });
          });

        } else {

          $state.loaded();
        }
      }, 200);
    },

    getPhaseIdeas() {
      this.$isLoading(true);
      this.phaseIdeas = [];
      return new Promise((resolve, reject) => {
        let qs = (this.filterModuleId > 0) ?
          "&process2ModuleId.equals=" + this.filterModuleId :
          "&process2ModuleId.in=" +  this.strPhaseModulesId.slice(0, -1);

        if (this.keywords) qs += "&title.contains=" + this.keywords;

        //#n: filtro scenario e challenge
        if (this.filterScenarioId > 0)
          qs += "&scenarioId.equals=" + this.filterScenarioId;

        if (this.filterChallengeId > 0)
          qs += "&challengeId.equals=" + this.filterChallengeId;
        // /

        // qs +=  (this.filterModuleId > 0) ? "&process2ModuleId.equals=" + this.filterModuleId :  +  this.strPhaseModulesId.slice(0, -1);
        // if(this.filterModuleId > 0)
        //   qs += "&process2ModuleId.equals=" + this.filterModuleId;

        if (this.orderField)
          qs += "&sort=" + this.orderField + "," + this.orderVersus;
        // qs += "&sort=" + "id" + "," + this.orderVersus;

        //Visualizza solo le idee pubbliche
        qs += "&ideaStatusId.equals=3";

        //Visualizza solo la verisiona pubblicata
        qs += "&versionCurrent.equals=true"

        //Paginazione di 24
        // qs += "&size=24"
        qs += ((this.view == 'map' || !this.orderField) ? "" :  ("&size=" + this.$options.static.visibleItemsPerPageCount + "&page=" + this.currentPage));

        let endpoint =  ((this.view == 'map' || !this.orderField) ? this.generateUrl('ideas', true) : this.generateUrl('ideas-page', true)) +  "?locs_=descr&fks_=logo,istatus,auth,isast&cnt_=i2foll,icomm&val_=lvv4" + qs;
                

        ApiService.get( this.$apiResource, endpoint)
        .then(res => {                        
          this.phaseIdeas = ((this.view == 'map' || !this.orderField) ? res?.data : res?.data?.content) || [];
          this.phaseIdeaTotal = res?.data?.meta?.total ?? this.phaseIdeas?.length;

          if (!this.orderField && this.view != 'map') {
            this.phaseIdeas = this.shuffle(this.phaseIdeas);
          }

          this.pageCount = res?.data?.meta?.pages ?? 1;
          this.displayViewMap = (this.phaseIdeas.some( el => el.centroid != null) && this.process?.cities?.length > 0);

          this.ideasInPs().then(() => resolve(1));
          
        })
        .catch(() => {                   
          reject(0);
        })
        .finally(() => {
          this.$isLoading(false);
        });
      });
    },

    ideasInPs() {

      return new Promise((resolve, reject) => {

        let qs = (this.filterModuleId > 0) ? "?process2ModuleId.equals=" + this.filterModuleId : "?process2ModuleId.in=" +  this.strPhaseModulesId.slice(0, -1);

        //Solo le idee in bozza
        qs += "&ideaStatusId.equals=1";

        //Solo la verisiona 1
        qs += "&versionNumber.equals=1";

        //Solo autore loggato
        qs += "&authorId.equals=" + this.currentUser.id;

        //canVersion attivo

        var endpoint = this.generateUrl('ideas/', false);
        //generateUrl is done
        ApiService.get(this.$apiResourceOpen, endpoint + qs )
          .then(async res => {

            if (res && res.data && res.data.length > 0) {

              for (var i = 0; i < res.data.length; i++) {

                endpoint = this.generateUrl('process-modules/' + res.data[i].process2ModuleId, false);
                await ApiService.get(this.$apiResourceOpen, endpoint)
                    .then(res2 => {

                      if(res2 && res2.data && res2.data.canVersion) {
                        this.ideaInPs = true;
                      }
                    })
              }
            }
          })
          .catch(() => {                     
            reject(0);
          });
      });
    },

    getSearchData(event) {
      event.preventDefault();
      this.getPhaseIdeas();
    },

    filterByModule(event, id = 0) {
      event.preventDefault();
      this.filterModuleId = id;
      this.currentPage = 1;
      this.getPhaseIdeas();

      this.selectedModuleFilter = id;
    },

    //#n
    filterByPhaseScenario(event, id = 0) {
      event.preventDefault();
      this.filterScenarioId = id;
      this.currentPage = 1;
      this.getPhaseIdeas();

      this.selectedScenarioFilter = id;
    },

    filterByPhaseChallenge(event, id = 0) {
      event.preventDefault();
      this.filterChallengeId = id;
      this.currentPage = 1;
      // this.getIdeas();
      this.getPhaseIdeas();

      this.selectedChallengeFilter = id;
    },
    // /nuovo

    localizeData(lang) {      
      this.ideaLang = lang;
      this.getPhaseIdeas();
    },

    setView(value, event) {
      event.preventDefault();
      this.ideaView = value;
      this.getPhaseIdeas();
    },
    orderBy(event, field = "id", versus = "desc") {
      event.preventDefault();
      this.orderField = field;
      this.orderVersus = versus;
      this.currentPage = 1;
      this.getPhaseIdeas();
    },

    managePasswordRecovery() {
      this.$refs["passwordRecovery"].$refs["modalPasswordRecovery"].show();
    },
    manageOTPVerify() {
      this.$refs["passwordMobileVerify"].$refs["modalPasswordMobileVerify"].show();
    },
    managePasswordChange() {
      this.$refs["passwordChange"].$refs["modalPasswordChange"].show();
    },
    shuffle(array) {
      var currentIndex = array.length,  randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    },
  }
};
</script>

<style scoped>
.card-panel {
  min-width: 350px;
  /* max-width: 400px; */
}

.phase-module-card {
  min-width: 350px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.selectedFilterStyle a {
  position: relative;
  display: inline-block;
}

.selectedFilterStyle a::after {
  position: absolute;
  content: "";
  display: block;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ee765c;
}

@media screen and (max-width: 730px) {
  .card-panel {
    border-right: none !important;
  }

  .phase-module-card {
    border-right: 1px solid #d6d6e0;
    margin-left: 0;
  }
}

@media screen and (max-width: 400px) {
  .card-panel {
    min-width: 250px;
  }
  .phase-module-card {
    min-width: 250px;
  }
}
</style>
